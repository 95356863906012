@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    p {
        @apply pb-8;
        @apply text-lg;
    }
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Playfair+Display:ital,wght@0,400;0,500;1,400&display=swap&display=swap');
@import './responsive.scss';

@import './hamburgers/hamburger-settings.scss';
@import './hamburgers/hamburgers.scss';

html,
body {
    background: #000;
}

body {
    margin: 0;
    font-family: 'Inter', sans-serif;
    color: #fff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.overflow-hidden {
    overflow: hidden;
}

p {
    line-height: 1.45em;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

article {
    font-size: 1.2rem;
}

a {
    &:visited {
        color: #fff;
    }

    &:not(.no-border) {
        text-decoration: underline;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 900;
    line-height: 1;
}

.centered {
    align-items: center;
}

.hero {
    min-height: 100vh;

    &.centered {
        display: flex;
        @extend .centered;
    }
}

.columns {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    &.centered {
        @extend .centered;
    }

    > div {
        display: flex;
        flex-direction: column;
    }
}

/* ie9+ */
.aural:not(:focus):not(:active) {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

ul {
    &.no-padding {
        list-style: none;
        margin: 0;
        padding: 0;
    }
}

.grid {
    display: grid;
    list-style: none;
    padding: 0;
    margin: 0;

    &.row {
        // background: ${theme.colors.gray.ultraDark};
        // column-gap: ${theme.spacing.plus4};
        // padding: ${theme.spacing.plus6} ${theme.spacing.plus8};
        list-style: none;
        margin: 0;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

        justify-items: center;
        img {
            width: 100%;
        }
    }
}
